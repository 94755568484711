.app-footer-container {
    background-color: #5E1914;
    color: white;
}

.app-footer-footer {
    text-align: right;
}

.footer-app-logo {
    height: 100px;
    width: auto;
}

.app-footer-menu-text {
    display: inline-block;
    padding: 5px 10px;
    font-size: 8px;
}