.letter-editor-toolbar {
    margin-left: auto;
    background-color: #ebeef0;
    position: relative;
}

.title-input {
    width: 25vh;
    height: 3vh;
    font-size: 1.2em;
}

.font-family-select {
    display: flex;
    border: 1px solid black;
    align-items: center;
    background-color: white;
}

.font-family-select-arrow {
    margin-left: auto;
}

.font-family-select-value {
    padding: 5px;
}

.font-family-select-arrow {
    padding: 5px;
}

.font-family-select:hover {
    cursor: pointer;
}

.font-option {
    padding: 5px;
}

.font-option:hover {
    cursor: pointer;
    background-color: #c3c5c5;
}

.font-select {
    position: relative;
    width: 25vh;
}

.font-toolbar-dropdown {
    background-color: white;
    height: 17vh;
    overflow-y: scroll;
    position: absolute;
}

.stationary-select {
    background-color: white;
    height: 30vh;
    width: 25vh;
    overflow-y: scroll;
}

.stationary-option {
    display: inline-block;
    padding: 10px;
}

.stationary-option-selected {
    display: inline-block;
    padding: 10px;
    border: 1px solid #5E1914;
}

.stationary-option-img {
    height: 40px;
    width: 40px;
}

.toolbar-container {
    margin: 0 10px;
    padding: 10px 0;
}

.toolbar-select-label {
    padding-bottom: 5px;
}

.toolbar-header {
    font-weight: bold;
}

.text-editor-container {
    padding: 10px;
    display: inline-flex;
}

.letter-editor-textarea {
    height: 65vh;
    min-width: 55vh;
    outline: none;
    border-radius: 15px;
    padding: 31px;
    font-size: 1.5em;
    font-family: Trebuchet MS, sans-serif;
}

.text-editor-preview-container {
    display: inline-flex;
    padding: 10px 50px;
    font-size: 1.3em;
    white-space: pre-line;
}

.preview-background {
    display: inline-block;
    border-radius: 15px;
    min-height: 74vh;
    width: 57vh;
    background-repeat: no-repeat;
    background-size: 100% 100%;
}

.preview-content {
    padding: 35px 35px;
    text-align: left;
}

.toolbar-button-group {
    position: absolute;
    bottom: 0;
    right: 0;
}

.toolbar-button-wrapper {
    padding: 10px 0;
    margin: 0 10px;
    display: inline-block;
}

.toolbar-button {
    background-color: #EE3E38;
	outline: none;
    border: 0;
    color: #fff;
	padding:10px 20px;
	text-transform:uppercase;
	border-radius:2px;
    cursor:pointer;
}

.auto-save-message {
    color: grey;
    font-size: 0.7em;
    margin: 0 10px;
    padding: 5px 0;
}

.view-import-image-anchor {
    text-decoration: underline;
}