.share-to-user-wrapper {
    /* display: flex; */
    padding-left: 10px;
    text-align: center;
}

.page-input {
    width: 20%;
}

.share-disclaimer-text {
    width: 30%;
    text-align: center;
    display: inline-block;
}

.center-button {
    text-align: center;
}