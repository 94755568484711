.share-date-container {
    text-align: center;
}

.date-text {
    width: 40%;
    display: inline-block;
    padding-bottom: 10px;
}

.date-picker-container {
    display: inline-block;
}