.home-page-header {
    background-color: white;
    display: flex;
    align-items:center;
    justify-content: left;
    font-family: "proxima-nova-regular";
    position: sticky; 
    top: 0;
    z-index: 1;
}

.app-logo-container {
    display: inline-block;
    padding: 0 20px;
}

.home-page-app-logo {
    height: 100px;
    width: auto;
}

.login-modal-wrapper {
    position: absolute; 
    left: 0; 
    right: 0; 
    margin-left: auto; 
    margin-right: auto; 
    background-color: white;
    outline: none;
    width: 40%;
    text-align: left;
    top: 15%;
}

.login-modal-header {
    text-align: center;
    margin-bottom: 20px;
}

.sign-in-header {
    display: inline-block;
    font-size: 1em;
    padding: 2vh;
    margin: 1vh;
}

.sign-in-header:hover {
    cursor: pointer;
}

.signin-wrapper {
    text-align: center;
}

.input-container{
	position:relative;
	margin-bottom:25px;
}
.input-container input{ 
    border:0;
    border-bottom:1px solid #555;  
    background:transparent;
    padding:8px 0 5px 0;
    font-size:16px;
    width: 60%;
}
.input-container input:focus{ 
    border:none;	
    outline:none;
    border-bottom:1px solid #0D67B4;	
}

.primary-button-wrapper {
    padding: 10px 0;
}

.primary-button {
    background-color: #EE3E38;
	outline: none;
    border: 0;
    color: #fff;
	padding:10px 20px;
	text-transform:uppercase;
	border-radius:2px;
    cursor:pointer;
}

.password-requirements-container {
    display: inline-block;
    text-align: left;
    width: 60%;
    padding-bottom: 10px;
}

.password-requirement-checklist {
    color: #33cc33;
}

.criteria-not-met {
    color: #e74c3c;
}

.password-requirements-header {
    font-weight: bold;
    font-size: 1.2 em;
    padding: 10px 0;
}

.sign-in-selected {
    border-bottom: 1px solid black;
}

a {
    text-decoration: none;
    color: white;
    color: inherit;
    overflow-x: clip;
}

.forget-password-text {
    padding: 10px;
}

.forget-password-text-link {
    text-decoration: underline;
}

.forget-password-text-link:hover {
    cursor: pointer;
}

.login-message {
    padding: 5px;
    border: 1px solid black;
    font-size: 0.8em;
    display: inline-block;
    border-radius: 5px;
    width: 70%;
}

.failure {
    color: red;
    background-color: lightpink;
    border-color: red;
}

.success {
    color: green;
    background-color: lightgreen;
    border-color: green;
}

.sign-in-buttons-header-container {
    display: inline-flex;
    margin-left: auto;
    padding: 0 20px;
}