.scheduled-updates-table-container {
    padding: 10px;
}

.updates-table-row-header {

}

.updates-table-row-header-label {
    padding: 5px;
}

.updates-table-row {

}

.updates-table-description {
    padding: 5px;
}

.scheduled-updates-body-container {
    min-height: 100vh;
}

.delete-row {
    cursor: pointer;
}

.scheduled-updates-empty-container {
    text-align: center;
    background: lightgrey;
    opacity: 50%;
    padding: 60px;
    margin: 10px;
}

.schedule-update-link {
    text-decoration: underline;
}