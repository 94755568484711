.letter-list-item-container {
    display: inline-flex;
    padding: 10px;
    height: 11em;
    width: 8.5em;
    margin: 35px;
    box-shadow: 4px 4px 7px rgb(0 0 0 / 59%);
    position: relative;
}

.letter-list-item-container:hover {
    box-shadow: 10px 10px 18px rgb(0 0 0 / 59%);
}

.folder {
    background-color: #FEF1DC;
    text-align: center;
    border: 1px solid black;
}

.document {
    background-repeat: no-repeat;
    background-size: 100% 100%;
}

.list-item-title {
}

.folder-title {
}

.document-title {
}

.letter-list-item-icon-container {
    position: absolute;
    bottom: 0;
    right: 0;
}

.list-item-icon {
    display: inline-flex;
    padding: 5px;
}

.list-item-icon:hover {
    cursor:pointer;
}

.truncated-body-text {
    font-size: 0.8em;
    padding-top: 10px;
}

.share-modal {
    top: 5%;
}