.letter-display-view-container {
}

.letter-view-item-container {
    display: inline-flex;
    position: relative;
}

.additional-detail-container {
    color: #0000006b;
    position: absolute;
    bottom: 0;
    margin-left: 35px;
    font-size: 0.8em;
}