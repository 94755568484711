.app-header {
    background-color: white;
    display: flex;
    align-items:center;
    font-family: "proxima-nova-regular";
    position: sticky; 
    top: 0;
    z-index: 1;
}

.account-container {
}

.profile-avatar {
    background-color: #5E1914;
    border-radius: 40px;
    margin-right: 20px;
    padding: 10px;
    color: white;
}

.app-logo-container {
    display: inline-block;
}

.app-logo {
    height: 70px;
    width: auto;
}

.account-container {
    cursor: pointer;
}

.horizontal-menu-icon-button {
    padding: 10px;
    color: #EE3E38;
    cursor: pointer;
}

.secondary-icon-button {
    color: #5E1914;
}

.flex-align-right {
    margin-left: auto;
}