.account-body-container {
    width: 100%;
    padding: 10px;
    min-height: 100vh;
}

.account-welcome-message {
    font-size: 3em;
}

.reset-password-container {
    width: 40%;
    padding-top: 10px;
}

.account-subheader {
    font-size: 2em;
}