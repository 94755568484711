.letters-page-container {
}

.letter-view-container {
    text-align: left;
    display: flex;
}

.letter-view-header {
    display: flex;
    align-items: center;
    background-color: #ebeef0;
    padding: 10px 10px;
}

.sort-by-text {
    display: inline-block;
    padding: 0 5px;
}

.sort-by-option {
    cursor: pointer; 
}

.sort-by-header {
    font-weight: bold;
}

.sort-selected {
    text-decoration: underline;
    color: #041A2D
}

.empty-letters-page-container {
    text-align: center;
    background-color: #ebeef0;
    margin: 50px 20%;
    padding: 10px;
    border-radius: 10px;
}

.error-loading {
    color: red;
    background-color: lightpink;
    border-color: red;
}

.filter-button {
    display: inline-flex;
    padding: 0 10px;
}

.filter-button-disabled {
    color: #EE3E38;
    background-color: white;
}