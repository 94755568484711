.letter-container {
    display: inline-block;
    padding: 10px 50px;
    white-space: pre-line;
}

.letter-view {
    display: inline-block;
    border-radius: 15px;
    background-repeat: no-repeat;
    background-size: 100% 100%;
}

@media (min-width:320px)  { /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */ }
@media (min-width:480px)  { /* smartphones, Android phones, landscape iPhone */ }
@media (min-width:600px)  { /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */ }
@media (min-width:801px)  { 
    /* tablet, landscape iPad, lo-res laptops ands desktops */
    .letter-container {
        font-size: 1.3em;
        height: 100%;
        width: 100%;
    }

    .letter-view {
        font-size: 1.3em;
        height: 90%;
        width: 33%;
    }
}
@media (min-width:1025px) { /* big landscape tablets, laptops, and desktops */ }
@media (min-width:1281px) { /* hi-res laptops and desktops */ }

.letter-content {
    padding: 35px 35px;
    text-align: left;
    min-height: 500px;
}

.public-view-container {
    height: 100vh;
}