.summary-container {
    text-align: center;
}

.summart-section-container {
    display: inline-block;
}

.share-summary-section {
    text-align: left;
    padding: 5px;
}

.share-summary-label {
    font-weight: bold;
    padding-right: 5px;
}

.share-summary-value {

}