.letter-share-navigation {
    display: flex;
    align-items: center;
    justify-content: center;
}

.letter-share-nav-container {
    text-align: center;
    padding: 30px;
}

.letter-share-nav-title {
    font-size: 12px;
    padding-bottom: 5px;
}

.letter-share-nav-selection {
    height: 10px;
    width: 10px;
    border: 1px solid black;
    border-radius: 20px;
    display: inline-flex;
}

.share-nav-selected {
    background-color: black;
}

.letter-share-nav-selection:hover {
    cursor: pointer;
}

.letter-share-nav-hr-container {

}

.letter-share-hr {
    width: 100px;
}