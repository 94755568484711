.welcome-view-container {
    background-repeat: no-repeat;
    background-size: 100% 100%;
    min-height: 100vh;
}

.welcome-view-info-container {
    display: inline-block;
    float: left;
    padding: 250px 100px;
    max-width: 33%;
}

.welcome-view-main-text {
    font-weight: bold;
    font-family: "Moon Dance";
    font-size: 3em;
}