.letter-share-container {
    width: 100%;
    min-height: 90vh;
}

.share-menu-section-header {
    padding-left: 10px;
}

.schedule-letter-success {
    text-align: center;
    background: lightgrey;
    opacity: 50%;
    padding: 60px;
    margin: 10px;
}

.successful-share-message {
    text-align: center;
    padding-bottom: 20px;
}

.share-container {
    text-align: center;
}

.share-message {
    margin: 10px;
    padding: 10px;
    width: 30%;
}

.share-document-component {
    overflow-y: scroll;
}

.modal-component-body {
    padding: 10px;
}

.share-option {
    border: 1px solid black;
    padding: 10px;
    margin: 10px;
    border-radius: 5px;
    display: flex;
    align-items: center;
}

.share-option:hover {
    box-shadow: 4px 4px 7px rgb(0 0 0 / 59%);
    cursor: pointer;
}

.share-option-title {
    font-weight: bold;
}

.share-option-body {

}

.share-option-icon {
    padding: 5px;
    margin: 5px;
}

.share-option-description-container {
}

.share-via-url-confirmation {
    padding: 10px;
    text-align: center;
}

.share-letter-via-url-disclaimer {
    text-align: left;
}

.share-url-input-container {
    margin: 10px;
}

.share-url-input {
    border-radius: 5px 0 0 5px;
    border-left: 1px solid black;
    border-bottom: 1px solid black;
    border-top: 1px solid black;
    border-right: none;
    width: 30%;
    outline: none;
    font-size: 20px;
    padding: 10px;
}

.share-url-copy-button {
    border-radius: 0 5px 5px 0;
    border: 1px solid black;
    cursor: pointer;
    font-size: 20px;
    padding: 10px;
    color: white;
    background-color: #86B3DA;
}

.auth-switch-label {
    display: inline-flex;
}

.share-letter-via-url-disclaimer {
    text-align: center;
}

.disclaimer-text {
    font-style: italic;
    text-align: left;
}

.qr-code {
    height: 20vh;
    width: auto;
}

.secret-code-body-text {

}

.secret-code {
    font-size: 1.5em;
    font-weight: bold;
}

.share-component-or {
    font-weight: bold;
    font-size: 15px;
}