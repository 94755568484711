.modal-component-title {
    font-weight: bold;
    font-size: 1.5em;
    padding: 10px;
}

.delete-body-text {
    padding: 10px;
}

.delete-letter-title {
    font-weight: bold;
    font-style: italic;
}

.modal-button-container {
    padding: 10px;
    text-align: right;
}

.delete-button {
    background-color: red;
}