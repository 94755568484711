.App {
  text-align: center;
}

input[type="text"],
input[type="button"],
input[type="password"] {
     -webkit-appearance: none;
}

@media (max-width:400px)  { /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */ 
  .App {
    width: 100vh;
  }

  .preview-background-image {
    width: 100%;
  }

  .letter-preview-content {
    font-size: 1.3em;
  }

  .how-it-works-icon {
    height: 50px;
    width: 50px;
  }

  .how-it-works-description-container {
    padding: 20px;
    max-width: 45%;
  }

  .how-it-works-header {
    font-size: 1.5em
  }

  .menu-button {
    font-size: 12px;
  }

  .vertical-app-menu-container {
    width: 15%;
  }

  .view-container {
    min-height: 80vh;
  }

  .single-letter-view-container {
    min-height: 80vh;
  }

  .font-family-selection-window {
    font-size: 9px;
  }
}
@media (max-width:480px)  { /* smartphones, Android phones, landscape iPhone */ 

}
@media (min-width:600px)  { /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */ }
@media (min-width:801px)  { 
  .preview-background-image {
    width: 55vh;
  }

  .editor-text-area {
    min-width: 55vh;
  }

  .how-it-works-icon {
    height: 75px;
    width: 75px;
  }

  .how-it-works-description-container {
    padding: 60px;
    max-width: 40%;
  }

  .how-it-works-header {
    font-size: 3em
  }

  .menu-button {
    font-size: 18px;
  }

  .vertical-app-menu-container {
    width: 5%;
  }

  .view-container {
    min-height: 100vh;
  }

  .single-letter-view-container {
    min-height: 100vh;
  }
}
@media (min-width:1025px) { /* big landscape tablets, laptops, and desktops */ }
@media (min-width:1281px) { /* hi-res laptops and desktops */ }

body {
  min-height: 100vh;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.menu-button {
  position: relative;
  background-color: white;
  border: none;
  cursor: pointer;
}

.menu-button::after {
  content: '';
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #5E1914;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.menu-button:hover::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.menu-button-container {
  display: inline-flex;
  padding: 0 15px;
}

.header-button {
  display: inline-flex;
}

.view-container {
  display: inline-block;
  margin-right: auto;
  overflow-y: scroll;
  width: 100%;
}

.page-loading-spinner {
  height: 100%;
  width: 100%;
  text-align: center;
}

.page-loading-spinner-container {
  margin: 25vh
}

.horizontal-button {
  display: inline-block;
  padding: 10px;
}

.modal-message {
  padding: 5px;
  border: 1px solid black;
  font-size: 0.8em;
  display: inline-block;
  border-radius: 5px;
  width: 70%;
}

.modal-message-wrapper {
  text-align: center;
  margin-top: 5px;
}

.modal-body-container {
  padding: 10px;
}

.modal-component-sub-text {
  padding-bottom: 10px;
}

.loading-text {
  padding: 10px 0;
}

.single-letter-view-container {
  padding: 10px;
}

.letter-view-import-text-container {
  padding: 10px;
}

.page-header {
  font-size: 3em;
  padding-left: 10px;
}

.page-subheader {
  font-size: 2em;
  padding-left: 10px;
}