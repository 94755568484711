.unlock-letter-input {
    display: block;
    margin: 1em auto;
    border: none;
    padding: 0;
    width: 7.5ch;
    background: repeating-linear-gradient(90deg, dimgrey 0, dimgrey 1ch, transparent 0, transparent 1.5ch) 0 100%/7ch 2px no-repeat;
    font: 5ch droid sans mono, consolas, monospace;
    letter-spacing: 0.5ch;
}

.unlock-letter-input:focus {
    outline: none;
}

.unlock-letter-text {
    padding: 10px;
}