.vertical-app-menu-container {
    background-color: #d6d6d6;
    display: inline-block;
    text-align: center;
    position: relative;
    color: white;
}

.vertical-menu-icon-menu-button {
    padding: 10px;
    color: #5E1914;
}

.logout-button {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    cursor: pointer;
}

.vertical-menu-selected {
    background-color: #5E1914;
    color: white;
}